<template>
  <section class="my-1">
    <div class="flex flex-col items-center justify-center pt-6 pb-3">
      <div class="bg-button w-28 h-28 rounded-full p-2 flex justify-center">
        <img :src="driverDetails.image" alt="Driver Image" class="w-24 h-24 rounded-full">
      </div>
      <p class="text-xl text-button font-bold py-6">{{ driverDetails.name }}ALi Test</p>
    </div>
    <hr>
    <div class="md:mx-24 mx-20 font-poppin text-lg font-semibold space-y-6 pt-2">
      <div class="flex justify-between">
        <p class="font-semibold">User Name:</p>
        <p>{{ driverDetails.username }} SGfvkh</p>
      </div>
      <div class="flex justify-between">
        <p class="font-semibold">Language:</p>
        <p>{{ driverDetails.language }} English</p>
      </div>
      <div class="flex justify-between">
        <p class="font-semibold">Vehicle #:</p>
        <p>{{ driverDetails.vehicle }} 32434vh</p>
      </div>
      <div class="flex justify-between">
        <p class="font-semibold">Contact #:</p>
        <p>{{ driverDetails.contact }} 0714545455</p>
      </div>
    </div>
    <router-link :to="{ name: 'ResetPassword' }">
      <div class="flex justify-center my-12">
        <button class="rounded-lg px-4 bg-button py-2 font-popins font-semibold text-lg shadow-lg text-white hover:bg-input focus:outline-none">
          Reset Password
        </button>
      </div>
    </router-link>
  </section>
</template>
<script>
import RepositoryFactory from "../repositories/RepositoryFactory";
import AuthenticationHelper from "../helpers/AuthenticationHelper";

const Suppliers = RepositoryFactory.get("supplier");

export default {
  data() {
    return {
      driverDetails: {},
      bookingDetails: {},
      supplierID: "",
      bookingID: this.$route.params.code,
      driverID: "",
    };
  },
  async created() {
    await this.checkLoggedIn();
    await this.getBookingDetails();
  },
  methods: {
    async getBookingDetails() {
  const bookingID = this.$route.params.code;
  try {
    const { data } = await Suppliers.getDriverBookingDetails(bookingID, this.driverID);
    if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
      const bookingData = data.output;
      this.bookingDetails = bookingData;
      this.driverDetails = {
        name: bookingData.name,
        image: bookingData.driver_image,
        vehicle: bookingData.vehicle_number,
        contact: bookingData.contact_number,
      };
    }
  } catch (error) {
    console.error('Error fetching booking details:', error);
    // Handle errors gracefully
  }
}
,
    async checkLoggedIn() {
      if (!AuthenticationHelper.checkUserAuthentication(this.$store.state)) {
        this.$router.replace({ name: "LoginScreen" });
      } else {
        const auth = AuthenticationHelper.getAuthUser(this.$store.state);
        this.driverID = AuthenticationHelper.getSupplierID(auth);
      }
    },
  },
};
</script>
