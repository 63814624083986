<template>
  <section class=" mx-2 md:mx-12">
   <div class="my-4 font-poppins ">
     <DriverDetails :driverDetails="driverDetails" :bookingService="bookingService" />
   </div>
   <div class="text-center mb-2 font-poppins">
     <h1 class="text-button font-semibold text-2xl">{{ tripDetails.bookingDate }}</h1>
     <span class="text-input text-lg m-2 mt-2">Assignment# {{ tripDetails.bookingNumber }}</span>
   </div>
   <div class="grid grid-cols-3 grid-rows-1 mt-2">
     <div class="p-1 border border-gray-200 text-center flex flex-col">
       <span class="text-input text-md font-semibold">Booking Time</span>
       <span class="text-heading text-lg font-semibold my-1">{{ tripDetails.bookingTime }}</span>
     </div>
     <div class="p-1 border border-gray-200 font-semibold text-center flex flex-col">
       <span class="text-input text-md">Total Guest</span>
       <span class="text-heading text-lg font-semibold my-1">{{ tripDetails.totalGuests }}</span>
     </div>
     <div class="py-1 border border-gray-200 font-semibold text-center flex flex-col">
       <span class="text-input text-md">Status</span>
       <span class="text-button text-lg md:text-lg font-semibold my-1">{{ tripDetails.status }}</span>
     </div>
   </div>
   <div class="pl-2 text-start flex flex-col mt-2">
     <span class="text-input font-semibold text-md">Pick up Location</span>
     <span class="text-heading text-lg font-semibold my-1">{{ tripDetails.pickUpLocation }}</span>
   </div>
   <div class="pl-2 text-start flex flex-col mb-1">
     <span class="text-input font-semibold text-md">Drop off Location</span>
     <span class="text-heading text-lg font-semibold my-1">{{ tripDetails.dropOffLocation }}</span>
   </div>
   <hr />
   <div>
     <div class="flex justify-between mx-20 mx-5 mt-4 block">
       <div class="p-1 text-center flex flex-col">
         <span class="text-input font-semibold text-md">Flight Time</span>
         <span class="text-heading text-lg font-semibold my-1">{{ tripDetails.flightTime != null ? tripDetails.flightTime : 'N/A' }}</span>
       </div>
       <div class="p-1 text-center flex flex-col">
         <span class="text-input font-semibold text-md">Flight number</span>
         <span class="text-heading text-lg font-semibold my-1">{{ tripDetails.flightNumber != null && tripDetails.flightNumber != '' && tripDetails.flightNumber != 'undefined' ? tripDetails.flightNumber : 'N/A' }}</span>
       </div>
     </div>
     <div class="flex justify-between mx-20 mx-5 mt-2 block">
       <div class="p-1 flex flex-col">
         <span class="text-input font-semibold text-md">Customer Name</span>
         <span class="text-heading text-lg font-semibold my-1">{{ bookingService ? bookingService.name : '' }}</span>
       </div>
       <div class="p-1 text-center flex flex-col">
         <span class="text-input font-semibold text-md">Phone Number</span>
         <span class="text-heading text-lg font-semibold my-1">{{ tripDetails.phoneNumber != null && tripDetails.phoneNumber != '' && tripDetails.phoneNumber != 'undefined' ? tripDetails.phoneNumber : 'N/A' }}</span>
       </div>
     </div>
   </div>
  </section>
 </template>
 
 <script>
 import DriverDetails from '@/components/DriverDetails.vue';
 export default {
   name: "TripDetails",
   components: { DriverDetails },
   props: {
     driverDetails: Object,
     tripDetails: Object,
     bookingService: Object,
   },
 };
 </script>
 