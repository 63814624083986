<template>
  <section>
    <div class="w-screen font-poppins">
      <ul
        class="flex justify-center md:text-md text-lg  font-bold text-gray-600 py-2 shadow"
      >
        <li
          class="flex items-center mx-2 rounded-lg"
          :class="{ active: isActiveLink('/BookingList') }"
        >
          <router-link
            to="/BookingList"
            class="flex items-center p-2 rounded-lg hover:bg-gray-100 hover:text-gray-500 group"
          >
            <span>Today Booking</span>
          </router-link>
        </li>
        <li
          class="flex items-center mx-2 rounded-lg"
          :class="{ active: isActiveLink('/BookingList/UpcomingBooking') }"
        >
          <router-link
            to="/BookingList/UpcomingBooking"
            class="flex items-center p-2 rounded-lg hover:bg-gray-100 hover:text-gray-500 group"
          >
            <span>Upcoming Booking</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="content-area relative">
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    isActiveLink(route) {
      return this.$route.path === route;
    },
  },

};
</script>

<style scoped>

.content-area {
  margin-top: 0px; 
}
.active {
  background-color: #3db24b; 
  color: white; 
}
</style>
