<template>
  <section>
    <div class="text-center m-2"></div>
    <form class="max-w-sm mx-auto mt-4 font-poppins">
      <label for="datepicker" class="block text-xl font-semibold text-input">Select Date:</label>
      <div class="mb-5">
        <PikadayWrapper @calendarDateSelected="calendarDateSelected" />
      </div>
      <div v-if="bookingsList.length > 0" class="mb-5">
        <div class="flex justify-center items-center mb-5">
          <input
            type="text"
            v-model="searchKey"
            @blur="searchOnBlur"
            @keyup.enter="search"
            placeholder="Search from list viewed"
            class="bg-gray-50 border border-gray-100 text-gray-900 text-sm rounded-md block p-2 mx-auto px-28 focus:outline-none"
          />
        </div>
      </div>
    </form>
    <hr class="mt-5" />
    <div>
      <div v-if="loading" class="flex justify-center items-center mt-56  lg:mt-44">
        <loadingAnimator :loading="loading" />
      </div>
      <div v-else-if="!loading && !filteredOrders.length">
        <div v-if="selectingDate">
          <div class="flex justify-center items-center mt-56  lg:mt-44">
            <h1 class="text-xl font-semibold text-button">
              Select date to view bookings!
            </h1>
          </div>
        </div>
        <div v-else>
          <div class="flex justify-center items-center mt-56  lg:mt-44">
            <h1 class="text-xl font-semibold text-button">
              No bookings for this date!
            </h1>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="filteredOrders.length > 0">
          <CardDetails
            v-for="(order, index) in filteredOrders"
            :key="index"
            :order="order"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PikadayWrapper from "@/components/PikadayWrapper.vue";
import CardDetails from "@/components/CardDetails.vue";
import RepositoryFactory from "../repositories/RepositoryFactory";
import LoadingAnimator from "@/components/LoadingAnimator";
const Suppliers = RepositoryFactory.get("supplier");

export default {
  components: {
    PikadayWrapper,
    CardDetails,
    LoadingAnimator,
  },
  data() {
    return {
      supplierID: this.$store.state.authentication.supplierID,
      supplierType: this.$store.state.authentication.supplierType,
      date: "",
      bookingsList: [],
      searchKey: "",
      loading: false,
      selectingDate: true, // Set to true initially
      performSearch: false, // Added performSearch data property
    };
  },
  methods: {
    async calendarDateSelected(date) {
      this.date = date;
      this.selectingDate = false; // Set to false when date is selected
      this.loading = true;
      await this.getBookings(date);
      this.loading = false;
      this.performSearch = false; // Reset performSearch flag when a new date is selected
    },
    async getBookings(bookingDate) {
      this.bookingsList = [];
      console.log("Fetching bookings for date:", bookingDate);
      const { data } = await Suppliers.getSupplierBookings(
        this.supplierID,
        this.supplierType,
        bookingDate
      );
      console.log("API response:", data);
      if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
        this.bookingsList = data.output[0].booking;
      }
    },
    searchOnBlur() {
      this.performSearch = true; // Set flag to true when input loses focus
    },
    search() {
      if (this.performSearch) {
        this.filteredOrders = this.bookingsList.filter((order) => {
          return Object.values(order).some((value) => {
            if (typeof value === "string") {
              return value.toLowerCase().includes(this.searchKey.toLowerCase());
            }
            return false;
          });
        });
        this.performSearch = false;
      }
    },
  },
  computed: {
    selectDateMessage() {
      return this.selectingDate ? "Select a date" : "";
    },
    filteredOrders() {
      if (!this.searchKey) {
        return this.bookingsList;
      }
      return this.bookingsList.filter((order) => {
        return Object.values(order).some((value) => {
          if (typeof value === "string") {
            return value.toLowerCase().includes(this.searchKey.toLowerCase());
          }
          return false;
        });
      });
    },
  },
};
</script>

<style>
input:focus {
  outline: 2px solid #474747;
}
</style>
