import BackofficeRepository from './BackofficeRepository';
import SuppliersRepository from './SuppliersRepository';

class RepositoryFactory {
  constructor() {
    this.repositories = {
      supplier: SuppliersRepository,
      backoffice: BackofficeRepository,
    };
  }

  /**
   * Retrieves the API Resource
   *
   * @param resource
   * @return {*}
   */
  get(resource) {
    return this.repositories[resource];
  }
}

export default new RepositoryFactory();
