<template>
  <div class="loader"></div>
</template>

<script>
export default {
  name: 'LoadingIndicator',
};
</script>

<style scoped>
.loader {
  width: 70px;
  aspect-ratio: 1;
  background:
    radial-gradient(farthest-side, #34ac44 90%, #0000) center/16px 16px,
    radial-gradient(farthest-side, rgb(214, 23, 112) 90%, #0000) bottom/12px 12px;
  background-repeat: no-repeat;
  animation: l17 1s infinite linear;
  position: relative;
}

.loader::before {    
  content: "";
  position: absolute;
  width: 8px;
  aspect-ratio: 1;
  inset: auto 0 16px;
  margin: auto;
  background: #969494;
  border-radius: 50%;
  transform-origin: 50% calc(100% + 10px);
  animation: inherit;
  animation-duration: 0.5s;
}

@keyframes l17 { 
  100% { transform: rotate(1turn); }
}
</style>
