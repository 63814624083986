<template>
  <section>
    <div v-if="loading" class="flex justify-center items-center  lg:mt-56 mt-72">
      <LoadingAnimator />
    </div>
    <div v-else>
      <TripDetail :driverDetails="driverDetails" :bookingService="bookingDetails.booking_services ? bookingDetails.booking_services[0] : []" :tripDetails="tripDetails" />

      <div class="flex justify-center mt-14">
        <div v-if="bookingDetails.booking_status.toLowerCase() === 'completed'">
          <span class="text-green-600 text-lg font-semibold">Successfully Completed</span>
        </div>
        <div v-else>
        <button
          v-if="showStatusButton && bookingDetails.booking_status.toLowerCase() !== 'rejected'"
          @click="saveBookingStatus(setNewStatus)"
          class="px-6 py-2 text-xl font-semibold bg-button hover:bg-bg-input text-white rounded-lg focus:outline-none"
        >
          {{ showStatus }}
        </button>
        </div>
      </div>
<div class="flex justify-center">
      <div class="flex justify-center mt-5">
        <button
          v-if="(bookingDetails.booking_status.toLowerCase() === 'ready for pickup') &&
                (bookingDetails.booking_services[0].status === '' ||
                 bookingDetails.booking_services[0].status === null)"
          @click="saveBookingStatus(setNewStatus)"
          class="px-7 bg-button py-2 text-lg font-semibold rounded-lg mt-5 mr-1 text-white hover:bg-input focus:outline-none"

        >
          Pick Up
        </button>
      </div>

      <div class="flex justify-center mt-5"
        v-if="
          showNoShowButton &&
          bookingDetails.booking_status.toLowerCase() === 'ready for pickup'
        "
      >
        <button
         
          @click="handleNotShownClick()"
          class="px-3 py-2 text-lg font-semibold bg-pink-700 hover:bg-input text-white rounded-lg mt-5 focus:outline-none"
        >
          Not Shown
        </button>
      </div>
    </div>

    <div
        v-if="showNoShowDialog"
        class="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-90 flex justify-center items-center"
      >
        <div class="bg-white rounded-lg p-4">
          <h2 class="text-xl font-semibold mb-4 flex justify-center ">Take Snapshot</h2>
          <div v-if="!imageCaptured" class="camera-modal">
            <video ref="video" class="camera-stream" autoplay></video>
          </div>
          <img
            v-if="imageCaptured"
            :src="imageSource"
            alt="Captured Image"
            class="mx-auto my-4"
          />
          <div class="flex justify-center">
            <button
              v-if="!imageCaptured && mediaStream"
              @click="capture"
              class=" mx-1 px-2 py-1 my-1 text-md font-semibold bg-button hover:bg-input text-white rounded-lg mt-5 focus:outline-none"
            >
              Take Snapshot
            </button>
            <button
              v-if="imageCaptured"
              @click="saveNoShowStatus"
              class=" mx-1 px-2 py-1 my-1 text-md font-semibold bg-button hover:bg-input text-white rounded-lg mt-5 focus:outline-none"
            >
              Save Status
            </button>
            <button
              v-if="imageCaptured"
              @click="resetImage"
              class=" mx-1 px-2 py-1 my-1 text-md font-semibold bg-gray-500 hover:bg-input text-white rounded-lg mt-5 focus:outline-none"
            >
              Retake
            </button>
            <button
             @click="cancelImage"
              class="mx-1 px-2 py-0 my-1 text-md font-semibold bg-pink-700 hover:bg-pink-900 text-white rounded-lg mt-5  focus:outline-none">Cancel</button>
          </div>
        </div>
      </div>


      <div class="flex justify-center mt-2">
        <button
          v-if="(bookingDetails.booking_status.toLowerCase() === 'ready for pickup') &&
                (bookingDetails.booking_services[0].status === '' ||
                 bookingDetails.booking_services[0].status === null)"
          @click="handleShowQRCode()"
          class="px-14 bg-gray-500 py-2 text-lg font-semibold rounded-lg text-lg font-semibold text-white hover:bg-input focus:outline-none"
        >
          Show QR Code
        </button>
      </div>

      <div v-if="showQRModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white rounded-lg shadow-lg p-6">
          <div class="flex flex-col items-center">
            <div class="text-center font-semibold">{{ bookingDetails.booking_services ? bookingDetails.booking_services[0].qr_code : "" }}</div>
            <div class="mt-4 text-center">
              <img :src="`${bookingDetails.booking_services ? qrCodeSrc : ''}`" alt="QR Code" class="w-64">
            </div>
            <div class="mt-4 text-center text-button font-semibold">Scan QR Code (For Customer)</div>
          </div>
          <div class="mt-6 flex justify-center">
            <button @click="showQRModal = false" class="px-4 py-2 text-lg font-semibold bg-pink-700 hover:bg-input text-white rounded-lg m-2 focus:outline-none">Close</button>
          </div>
        </div>
      </div>

      <div class="flex justify-center mb-14">
        <button
          v-if="bookingDetails.booking_status.toLowerCase() === 'new'"
          @click="saveBookingStatus('REJECTED')"
          class="px-8 py-2 text-lg font-semibold bg-pink-700 text-white rounded-lg hover:text-white hover:bg-input focus:outline-none"
        >
          Reject Job
        </button>
      </div>
    </div>
    <div v-if="showSaveMsg" class="error-popup text-md">
    Image saved successfully
  </div>
  </section>
</template>

<script>
import TripDetail from "@/components/TripDetail";
import RepositoryFactory from "../repositories/RepositoryFactory";
import AuthenticationHelper from "../helpers/AuthenticationHelper";
import LoadingAnimator from "@/components/LoadingAnimator";

const Suppliers = RepositoryFactory.get("supplier");

export default {
  components: { TripDetail, LoadingAnimator },
  data() {
    return {
      mediaStream: null,
      imageCaptured: false,
      imageSource: "",
      blob: null,
      showNoShowDialog: false,
      showNoShowButton: true,
      showRetakeOption: false,
      driverDetails: {},
      tripDetails: [],
      bookingDetails: {},
      loading: true,
      latitude: "",
      longitude: "",
      supplierID: "",
      bookingID: this.$route.params.code,
      pickupFlag: false,
      driverID: "",
      showQRModal: false,
      showSaveMsg : false,
    };
  },
  async created() {
    await this.checkLoggedIn();
    await this.getBookingDetails();
    await this.locateMe();
    await this.checkPickup();
    this.loading = false;
  },
  computed: {
 
    showStatusButton() {
      if (
        (this.bookingDetails.booking_status.toLowerCase() ===
          "ready for pickup" &&
          !this.pickupFlag) ||
        this.bookingDetails.booking_status.toLowerCase() === "completed" ||
        this.bookingDetails.booking_status.toLowerCase() === "completed*"
      ) {
        return false;
      }
      return true;
    },
    qrCodeSrc() {
      return this.bookingDetails.booking_services[0].qr_code_image;
    },
    showStatus() {
      if (this.bookingDetails.booking_status.toLowerCase() === "new") {
        return "Accept Job";
      }
      if (this.bookingDetails.booking_status.toLowerCase() === "confirmed") {
        return "Start Service";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "service started"
      ) {
        return "Ready for Pickup";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "ready for pickup" &&
        this.pickupFlag
      ) {
        return "Ride Complete";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "pax in vehicle"
      ) {
        return "Ride Complete";
      }
      return "";
    },
    setNewStatus() {
      if (this.bookingDetails.booking_status.toLowerCase() === "new") {
        return "CONFIRMED";
      }
      if (this.bookingDetails.booking_status.toLowerCase() === "confirmed") {
        return "SERVICE STARTED";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "service started"
      ) {
        return "READY FOR PICKUP";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "ready for pickup" &&
        !this.pickupFlag
      ) {
        return "PAX IN VEHICLE";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() ===
          "ready for pickup" &&
        this.pickupFlag
      ) {
        return "COMPLETED";
      }
      if (
        this.bookingDetails.booking_status.toLowerCase() === "pax in vehicle"
      ) {
        return "COMPLETED";
      }
      return "";
    },
  },
  methods: {
  initializeCamera() {
    if (!this.mediaStream) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((mediaStream) => {
          this.mediaStream = mediaStream;
          this.$refs.video.srcObject = mediaStream;
        })
        .catch((error) => console.error("getUserMedia() error:", error));
    }
  },
  resetCamera() {
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      this.mediaStream = null;
    }
  },
  stopCamera() {
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
        this.mediaStream = null;
      }
    },
  capture() {
    const mediaStreamTrack = this.mediaStream.getVideoTracks()[0];
    const imageCapture = new ImageCapture(mediaStreamTrack);
    imageCapture.takePhoto()
      .then((blob) => {
        const objectURL = URL.createObjectURL(blob);
        this.imageSource = objectURL;
        this.imageCaptured = true;
        this.blob = blob;
      })
      .catch((error) => console.error("Error capturing image:", error));
  },
  resetImage() {
    if (this.mediaStream) {
       
       this.mediaStream.getTracks().forEach((track) => {
         track.stop();
       });
    this.imageCaptured = false;
    this.imageSource = "";
    this.blob = null;
    this.resetCamera(); 
    this.initializeCamera(); 
    
  }
},
  cancelImage() {
  this.resetImage(); 
  this.showNoShowDialog = false; 
  this.loading = false; 
  this.stopCamera();
  window.location.reload();
},
saveNoShowStatus() {
  this.showSaveMsg = true;
  this.showNoShowDialog = false;
  
  
  this.resetCamera();
  this.loading = true;


  setTimeout(() => {
    this.getBookingDetails().then(() => {
      this.loading = false;
      this.showSaveMsg = false;
    });
  }, 3000); 
},
beforeDestroy() {
    this.stopCamera();
},
  handleNotShownClick() {
    this.showNoShowDialog = true;
    this.resetImage();
    this.initializeCamera(); 
  },
    handleShowQRCode() {
      this.showQRModal = true;
    },
    async checkPickup() {
      let pickupFlag = true;
      this.bookingDetails.booking_services.forEach((service) => {
        if (service.status === "" || service.status === null) {
          pickupFlag = false;
        }
      });
      this.pickupFlag = pickupFlag;
    },
    async getLocation() {
      try {
        const location = await navigator.permissions.query({
          name: "geolocation",
        });
        if (location.state === "denied") {
         
          console.log(
            "Geolocation permission has been blocked. Please reset it in your browser settings to enable location services."
          );
          return null;
        }

        if (location.state === "granted" || location.state === "prompt") {
       
          return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              (pos) => resolve(pos),
              (err) => reject(err)
            );
          });
        }
      } catch (error) {
        console.error("Error accessing geolocation:", error);
        return null;
      }
    },
    async locateMe() {
      try {
        const location = await this.getLocation();
        this.latitude = location.coords.latitude;
        this.longitude = location.coords.longitude;
      } catch (e) {
        this.latitude = "";
        this.longitude = "";
      }
    },
    setBookingStatus(value) {
      this.newStatus = value;
    },
    async saveBookingStatus(status) {
      this.loading = true;
      await this.locateMe();
      await Suppliers.updateSupplierBookingStatus(
        this.bookingID,
        this.supplierID,
        this.driverID,
        status,
        this.latitude,
        this.longitude
      );
      await this.getBookingDetails();
      this.loading = false;
    },
    async getBookingDetails() {
      const bookingID = this.$route.params.code;
      const { data } = await Suppliers.getDriverBookingDetails(
        bookingID,
        this.driverID
      );
      if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
        const bookingData = data.output;
        this.bookingDetails = bookingData;
        this.tripDetails = {
          bookingNumber: bookingData.booking_number,
          bookingDate: bookingData.booking_date,
          pickUpTime: bookingData.booking_time,
          totalGuests: bookingData.total_adult,
          status: bookingData.booking_status,
          pickUpLocation: bookingData.pickup_point,
          dropOffLocation: bookingData.dropoff_point,
          flightTime: bookingData.supplier_time,
          flightNumber: bookingData.flight_number,
          customerName: bookingData.name,
          phoneNumber: bookingData.flight_number,
          bookingTime: bookingData.booking_time,
        };
        this.driverDetails = {
          name: bookingData.name,
          image: bookingData.driver_image,
          vehicle: bookingData.vehicle_number,
          contact: bookingData.contact_number,
        };
    
      }
    },
      
    
    async checkLoggedIn() {
      if (!AuthenticationHelper.checkUserAuthentication(this.$store.state)) {
        this.$router.replace({ name: "LoginScreen" });
      } else {
        const auth = AuthenticationHelper.getAuthUser(this.$store.state);
        this.driverID = AuthenticationHelper.getSupplierID(auth);
      }
    },
  },
};
</script>

<style>

.no-show-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.camera-stream {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
}
.error-popup {
  position: fixed;
  top: 20px;
  left: 50%;
  color:white;
  transform: translateX(-50%);
  background-color: #a0a0a0;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(219, 224, 220, 0.1);
  z-index: 9999;
}
</style>
