<template>
  <div>
    <div class="flex justify-center p-2"><h1 class="text-button font-semibold text-3xl font-poppins">Bookings List</h1></div>
    
    <TodayBooking />
  </div>
</template>

<script>
import TodayBooking from "@/components/TodayBooking.vue";
export default {
  name: "HomeView",
  components: {
    TodayBooking,
  },
};
</script>
