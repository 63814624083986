<template>
  <section>
    <div class="text-center m-2">
    </div>
    <form class="max-w-sm mx-auto mt-2 focus:outline-none">
      <label for="datepicker" class="block text-xl font-semibold text-gray-600">Select Date:</label>
    
      <div class="mb-5">
        <PikadayWrapper :bookingDates="bookingDates" @calendarDateSelected="calendarDateSelected" />
      </div>
    </form>
    <hr class="mt-5">
    <div>
      
      <div v-if="loading" class="flex justify-center items-center mt-56 lg:mt-44">
<loadingAnimator :loading="loading" />
</div>
      <div v-else-if="!selectingDate && !loading">
        <div v-if="bookingsList.length > 0">
          <CardDetails v-for="(order, index) in bookingsList" :key="index" :order="order" />
        </div>
        <div v-else class="flex justify-center items-center mt-56 lg:mt-44">
          <h1 class="text-xl font-semibold text-button">No bookings for this date!</h1>
        </div>
      </div>
      <div v-else>
        <div class="flex justify-center items-center mt-56 lg:mt-44">
          <h1 class="text-xl font-semibold text-button">Select date to view bookings!</h1>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import PikadayWrapper from './PikadayWrapper.vue';
import CardDetails from './CardDetails.vue';
import LoadingAnimator from '@/components/LoadingAnimator'; 
import RepositoryFactory from '../repositories/RepositoryFactory';

const Suppliers = RepositoryFactory.get('supplier');

export default {
  components: {
    PikadayWrapper,
    CardDetails,
    LoadingAnimator, 
  },
  data() {
    return {
      supplierID: this.$store.state.authentication.supplierID,
      supplierType: this.$store.state.authentication.supplierType,
      date: '',
      bookingsList: [],
      loading: false,
      selectingDate: true, 
 
    };
  },
  created() {

  },
  computed: {
    selectDateMessage() {
      return this.selectingDate ? "Select a date" : "";
    },
  },
  methods: {
    
    async checkLoggedIn() {
      if (!this.$store.state.authentication.authenticated) {
        this.$router.replace({ name: 'LoginScreen' });
      } else {
        this.supplierID = this.$store.state.authentication.supplierID;
        this.supplierType = this.$store.state.authentication.supplierType;
      }
    },

    async calendarDateSelected(date) {
      this.date = date;
      this.selectingDate = false; 
      this.loading = true;
      await this.getBookings(date);
      this.loading = false;
    },

    async getBookings(bookingDate) {
      this.bookingsList = [];
      console.log("Fetching bookings for date:", bookingDate);
      const { data } = await Suppliers.getSupplierBookings(this.supplierID, this.supplierType, bookingDate);
      console.log("API response:", data);
      if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
        this.bookingsList = data.output[0].booking;
      }
  },
}
}
</script>

<style>
.v-picker__title {
  display: none !important;
}
</style>