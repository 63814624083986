import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      authentication: {
        authenticated: false,
        supplierID: '',
        supplierName: '',
        supplierEmail: '',
        supplierType: '',
        driverID: '',
        driverName: '',
      },
    };
  },

  mutations: {
    supplierLogIn(state, supplier) {
      state.authentication.authenticated = true;
      state.authentication.supplierID = supplier.id;
      state.authentication.supplierName = supplier.name;
      state.authentication.supplierEmail = supplier.email;
      state.authentication.supplierType = supplier.supplier_type;
      localStorage.setItem('vuex-authentication', JSON.stringify(state.authentication));
    },

    driverLogIn(state, driver) {
      state.authentication.authenticated = true;
      state.authentication.driverID = driver.id;
      state.authentication.driverName = driver.first_name;
      localStorage.setItem('vuex-authentication', JSON.stringify(state.authentication));
    },

    logOut(state) {
      state.authentication.authenticated = false;
      state.authentication.supplierID = '';
      state.authentication.supplierName = '';
      state.authentication.supplierEmail = '';
      state.authentication.supplierType = '';
      state.authentication.driverID = '';
      state.authentication.driverName = '';
      localStorage.removeItem('vuex-authentication');
    },

    initialiseAuthentication(state) {
      const savedAuthentication = localStorage.getItem('vuex-authentication');
      if (savedAuthentication) {
        state.authentication = JSON.parse(savedAuthentication);
      }
    },
  },

  actions: {
    initializeAuthentication({ commit }) {
      commit('initialiseAuthentication');
    }
  },
});

// Initialize authentication state from local storage
store.dispatch('initializeAuthentication');

export default store;
