<template>
  <router-link :to="{ name: 'DetailBooking', params: { code: order.booking_id } }">
    <div class="rounded my-6 pt-4 pb-2 md:mx-8 mx-1 bg-zinc-50 shadow-md font-poppins">
      <div class="flex justify-between">
        <div class="flex">
          <div :class="[ 'h-12','text-gray-100', 'w-14', 'mr-1','mt-3.5', 'flex', 'rounded', timeColorClass]">
            <p class="ml-[6px] text-md font-normal ">{{ order.booking_time }}</p>
          </div>
          <div class="flex flex-col">
            <p class="font-semibold text-lg text-gray-700">{{ order.customer_name }}</p>
            <p class="text-gray-500 font-semibold">{{ order.booking_title }}</p>

            <p class="text-button font-semibold">{{ order.booking_date }}</p>
          </div> 
        </div>
        <div>
          <div class="flex">
            <div class="flex flex-col p-1 ml-1">
              <p class="font-semibold text-gray-700">{{ order.booking_number}}</p>
              <p class="text-button text-md font-semibold block ">{{ order.booking_status }}</p>
            </div>
            <button class="bg-gray-600 h-12 w-12 flex justify-center rounded my-3 mr-1 p-2.5 hover:bg-gray-400">
              <i class="fa-solid fa-arrow-right text-md text-white mt-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    order: Object,
  },
  computed: {
    timeColorClass() {
      switch (this.order.booking_status) {
        case 'NEW':
          return 'bg-indigo-500'; 
        case 'COMPLETED':
          return 'bg-button';
        case 'REJECTED':
          return 'bg-rose-600';
        default:
          return 'bg-neutral-500';
      }
    },
  },
};
</script>
