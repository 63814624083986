<template>
  <div class="text-gray-700 font-poppins">

<div class="flex justify-center">
  
  <div class="flex bg-button rounded-full justify-between md:w-[90%]  w-[100%] h-[80px] ">

        
<i class="fas fa-user-circle text-gray-600 text-6xl text-white my-2 mx-1"
@click="openModal"
>
</i>
<span class="text-lg text-white font-semibold m-4 text-center cursor-pointer" @click="openModal">
                  {{ bookingService ? bookingService.name : '' }}
              </span>

<div>
  <h2 class="md:text-md text-lg text-gray-700 font-bold rounded-full cursor-pointer bg-white hover:bg-input hover:text-white px-5 py-2 m-4"
  @click="openModal">
  View
  </h2>

</div>
</div>
</div>
    <div
      v-if="showModal"
      class="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
    >
      <div class="bg-white w-full h-full flex justify-center items-center">
        <div class="bg-white rounded shadow w-full h-screen mt-4">
          <div class="flex justify-between items-center mt-6 font-semibold">
            <h2 class="text-2xl pl-4">Waiting For</h2>
            <button
              @click="closeModal"
              class="pr-4 text-gray-500 hover:text-pink-600 text-3xl"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>

          <hr class="my-4" />
          <div class=" mb-14 mx-auto w-screen">
              <div class="flex justify-center items-center mt-72">
                  <span class="font-semibold text-center">
                      <span class="text-6xl md:text-8xl lg:text-9xl">
                          {{ bookingService ? bookingService.name : '' }}
                      </span>
                  </span>
              </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    driverDetails: Object,
    bookingService: Object,
  },
  setup() {
    const showModal = ref(false);

    const openModal = () => {
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    const toggleDetailsVisibility = () => {

    };

    return {
      showModal,
      openModal,
      closeModal,
      toggleDetailsVisibility,
    };
  },
};
</script>
