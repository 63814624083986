// router/index.js
import { createRouter, createWebHistory } from "vue-router";
import LoginScreen from "@/view/LoginScreen.vue";
import HomeView from "@/view/HomeView.vue";
import BookingList from "@/view/BookingList.vue";
import RidesHistory from "@/view/RidesHistory.vue";
import UserProfile from "@/view/UserProfile.vue";
import TodayBooking from "./components/TodayBooking.vue";
import UpcomingBooking from "./components/UpcomingBooking.vue";
import DetailBooking from "@/view/DetailBooking.vue";
import ResetPassword from "./components/ResetPassword.vue";


const routes = [
  {
    path: "/BookingList",
    component: BookingList,
    children: [
      { path: "", component: TodayBooking },
      { path: "UpcomingBooking", component: UpcomingBooking },
    ],
  },
  { path: "/", name: "LoginScreen", component: LoginScreen },
  { path: "/HomeView", name: "HomeView", component: HomeView },
  {
    path: "/DetailBooking/:code",
    name: "DetailBooking",
    component: DetailBooking,
  },
  { path: "/RidesHistory", component: RidesHistory },
  // { path: "/PickedUp", name: "PickedUp", component: PickedUp },
  { path: "/UserProfile", component: UserProfile },
  {path:"/ResetPassword", component: ResetPassword , name:"ResetPassword"}
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
