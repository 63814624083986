<template>
  <div>
    <VueDatePicker v-model="date" auto-apply  model-type="yyyy-MM-dd" :format="dateFormat" :markers="customMarkers"></VueDatePicker>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import RepositoryFactory from '../repositories/RepositoryFactory';
const Suppliers = RepositoryFactory.get("supplier");

export default {
  components: { VueDatePicker },
  data() {
    return {
      date: '',
      dateFormat: 'yyyy-MM-dd',
      supplierID: "",
      supplierType: "",
      customMarkers: [],
      class:"custom-datepicker"
    };
  },
  mounted() {
    this.supplierID = this.$store.state.authentication.supplierID;
    this.supplierType = this.$store.state.authentication.supplierType;
    this.getAllBookings();
  },
  watch: {
    date(newDate) {
      this.$emit('calendarDateSelected', newDate);
    },
  },
  methods: {
    calendarDateSelected(date) {
      console.log("Selected date:", date);
      this.date = date;
      this.dateSelected(date);
    },
    async getAllBookings() {
      this.customMarkers = [];
      const { data } = await Suppliers.getSupplierBookings(this.supplierID, this.supplierType, '');
      if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
        const today = new Date().toISOString().slice(0, 10);
        data.output.forEach((value) => {
          const obj = {
            color: value.date < today ? 'red' : value.date === today ? 'green' : '',
            date: value.date,
          };
          this.customMarkers.push(obj);
        });
      }
    },
  }
};
</script>

<style scoped>
.dp__theme_light {
 

  --dp-cell-size: 41px; 
  --dp-cell-padding: 2px; 
  --dp-font-size: 1.2rem; 
  --dp-text-color: #4d4545;
  --dp-icon-color: #1fb845;
}
</style>