import { createApp } from 'vue';
import App from './App.vue';
import store from './store/index.js';
import router from './router';
import 'pikaday/css/pikaday.css';
import "./index.css"
import '@fortawesome/fontawesome-free/css/all.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
createApp(App)
  .use(store) 
  .use(router)
   .use(VueDatePicker)
  .mount('#app');
