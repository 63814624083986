<template>
  <div>
    <div v-if="loading" class="flex justify-center items-center mt-72 lg:mt-44">
      <loadingAnimator :loading="loading" />
    </div>
    <div v-else-if="!loading">
      <div v-if="orders.length > 0">
        <CardDetails
          v-for="(order, index) in orders"
          :key="index"
          :order="order"
        />
      </div>
      <div v-else class="flex justify-center items-center mt-72 lg:mt-44 ">
      <h1 class="text-xl text-button font-semibold">
        No Bookings for today!
      </h1>
    </div>
    </div>
   
  </div>
</template>

<script>
import CardDetails from "@/components/CardDetails.vue";
import LoadingAnimator from "@/components/LoadingAnimator"; 
import RepositoryFactory from "../repositories/RepositoryFactory";

const Suppliers = RepositoryFactory.get("supplier");

export default {
  components: {
    CardDetails,
    LoadingAnimator, 
  },
  data() {
    return {
      orders: [],
      date: "",
      supplierID: "",
      supplierType: "",
      booking_date: new Date().toISOString().substr(0, 10),
      loading: false,
    };
  },

  created() {
    this.checkLoggedIn();
   // console.log("Today's date:", this.booking_date);
  },

  methods: {
    async checkLoggedIn() {
      if (!this.$store.state.authentication.authenticated) {
        this.$router.replace({ name: "LoginScreen" });
      } else {
        this.supplierID = this.$store.state.authentication.supplierID;
        this.supplierType = this.$store.state.authentication.supplierType;
        this.fetchTodayBookings(this.booking_date);
      }
    },

    async fetchTodayBookings(bookingDate) {
      try {
        this.loading = true;
       console.log("Fetching bookings for date:", bookingDate);
        const { data } = await Suppliers.getSupplierBookings(
          this.supplierID,
          this.supplierType,
          new Date(bookingDate.replace('GMT', 'UTC')).toISOString().split('T')[0]
        );

        if (data.statuscode === process.env.VUE_APP_API_STATUS_SUCCESS) {
          this.orders = data.output[0].booking;
          console.log("orders", this.orders);
        } else {
          console.error("API Error:", data.error);
        }
      } catch (error) {
        console.error("Network Error:", error);
      } finally {
        this.loading = false;
      }
    },

    dateSelected(date) {
      this.booking_date = date;
      this.fetchTodayBookings(date);
    },
  },
};
</script>
