<template>
  <section>
    <div class="font-poppins">
      <nav v-if="$route.name !== 'LoginScreen'" class="bg-white py-4 md:px-8 px-2 w-screen shadow z-10">
        <div class="flex items-center justify-between">
          <div class="pl-2 cursor-pointer" @click="handleToggleSidebar">
              <i class="fa-solid text-3xl max-pl-2 w-6 text-gray-700 " :class="{ 'fa-bars-staggered': !isOpen, 'fa-times': isOpen }"></i>
            </div>
           
            <div class="flex justify-center content-center">
              <img src="@/assets/logo.svg" alt="log" class="md:h-8 h-7">
            </div>
         
          <div>
            <button @click="LogOut" class="rounded-lg md:px-8 px-4 bg-button py-2 text-lg font-semibold shadow-lg text-white hover:bg-input focus:outline-none justify-right flex">
              Logout
            </button>
          </div>
        </div>
      </nav>

      <div v-if="isOpen" class="flex shadow">
        <div class="w-screen">
          <ul class="flex justify-center text-lg md:text-xl font-bold text-gray-600 py-4 ">
            <li :class="{ 'bg-button text-white': activeLink === 'bookingList' }" class="flex items-center mx-2 rounded-lg">
              <router-link
                to="/BookingList"
                class="flex items-center p-2 rounded-lg hover:bg-gray-100 hover:text-gray-500 group"
                @click="handleNavClick('bookingList')"
              >
                <span>Booking List</span>
              </router-link>
            </li>
            <li :class="{ 'bg-button text-white': activeLink === 'ridesHistory' }" class="flex items-center mx-2 rounded-lg">
              <router-link
                to="/RidesHistory"
                class="flex items-center p-2 rounded-lg hover:bg-gray-100 hover:text-gray-500 group"
                @click="handleNavClick('ridesHistory')"
              >
                <span>Rides History</span>
              </router-link>
            </li>
            <li :class="{ 'bg-button text-white': activeLink === 'UserProfile' }" class="flex items-center mx-2 rounded-lg">
              <router-link
                to="/UserProfile"
                class="flex items-center p-2 rounded-lg hover:bg-gray-100 hover:text-gray-500 group"
                @click="handleNavClick('UserProfile')"
              >
                <span>Profile</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name:'NavBar',
  data() {
    return {
      isOpen: false,
      activeLink: null,
    };
  },
  created() {
    this.LogOutUser();
  },
  methods: {
    handleToggleSidebar() {
      this.isOpen = !this.isOpen;
    },
    LogOut() {
      this.$store.commit('logOut');
      this.$router.push({ name: 'LoginScreen' });
    },
    setActiveLink(link) {
      this.activeLink = link;
    },
    LogOutUser() {
      if (!this.$store.state.authentication.authenticated) {
        this.$router.replace({ name: 'LoginScreen' });
      }
    },
    handleNavClick(link) {
      this.activeLink = link;
      if (link === 'bookingList') {
        this.isOpen = false;
      }
    },
  }
};
</script>